.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  overflow-y: scroll;
}

.popup-container {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 80%;
  margin-top: 30px;
  margin-bottom: 120px;
  // max-height: 90%;
  // overflow-y: scroll;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.popup-content {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  h3 {

  }

   p {
    margin: 0;
    margin-bottom: 30px;
    max-width: 80%;
   }

  .popup-buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      margin: 5px;
    }
  }

}

