.profile-plans-container {
  position: relative;
  width: 90%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 100px;
  padding-bottom: 180px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 900px) {
    margin-top: 30px;
  }

  h2 {
    font-size: 40px;
    margin-bottom: 60px;
    text-align: center;
  }

  .profile-plan-cards-container {
    display: flex;
    gap: 30px;
    justify-content: center;
    flex-wrap: wrap;

    .profile-plan-card {
      position: relative;
      width: 300px;
      border: 1px solid #ddd;
      background-color: #fff;
      border-radius: 24px;
      padding: 30px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      text-align: left;
      transition: transform 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;

      &:hover {
        transform: translateY(-5px);
      }

      span {
        display: inline-block;
        font-size: 14px;
        margin-top: 10px;

        .cancel-sub {
          display: inline-block;
          font-size: 12px;
          font-weight: 600;
          margin-left: 10px;
          color: #e45353;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      h3 {
        position: relative;
        font-size: 16px;
        margin: 0;
        width: auto;
        display: inline-block;
        text-align: center;
        letter-spacing: 2px;
        padding-bottom: 10px;
        border-bottom: 1px solid #000;
      }

      .price {
        margin: 0;
        font-size: 40px;
        margin-top: 30px;
        margin-bottom: 30px;
        font-weight: 600;

        span {
          font-size: 20px;
          color: #666;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0px;
        margin-bottom: 50px;

        li {
          position: relative;
          margin: 15px 0px;
          font-size: 15px;
          font-weight: 500;
          color: #000;

          &::before {
            content: "";
            width: 20px;
            height: 20px;
            background-color: #4C4B47;
            border-radius: 50%;
            position: absolute;
            left: -30px;
            top: 50%;
            transform: translateY(-50%);
            background-size: contain;
            background-position: center;
            background-image: url('../../assets/checkmark-white.svg');
          }

        }
      }

      .plan-buy-button {
        width: 100%;
        border-radius: 30px;
        height: 50px;
        border: 1px solid #0090F2;
      }

      .active-sub-button {
        background-color: #fff;
        border: 1px solid #0090F2;
        color: #0090F2;
        cursor: auto;
      }

    }

    .student {
      h3 {
        color: #9E5D02;
      }
    }

    .pro {
      h3 {
        color: #6E9BA3;
      }
    }

    .event-organizer {
      h3 {
        color: #F2A01B;
      }
    }
  }
}

.loading-plans {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}